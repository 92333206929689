import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link as GatsbyLink } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, imgBox, link, desktop, mobile } from './soplica-wedel.module.scss';
import { IPage } from '../models/page.model';
import { ImageDataLike } from '../models/image-data-like.model';

import SEO from '../components/seo';

interface ISoplicaWedelProps {
    readonly data: {
        page: IPage | null;
        plugDesktop: ImageDataLike;
        plugMobile: ImageDataLike;
    };
}

const SoplicaWedel: React.FC<ISoplicaWedelProps> = ({ data }) => {
    const { language, defaultLanguage } = useI18next();
    const { page, plugDesktop, plugMobile } = data;
    const plugDesktopImg = getImage(plugDesktop);
    const plugMobileImg = getImage(plugMobile);

    return (
        <>
            <SEO meta={page?.meta} />
            <div className={container}>
                <div className={imgBox}>
                    <GatsbyLink
                        to={language === defaultLanguage ? '/' : `/${language}/`}
                        className={link}
                    />
                    <div className={desktop}>
                        {plugDesktopImg && <GatsbyImage image={plugDesktopImg} alt="" />}
                    </div>
                    <div className={mobile}>
                        {plugMobileImg && <GatsbyImage image={plugMobileImg} alt="" />}
                    </div>
                </div>
            </div>
        </>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        plugDesktop: file(relativePath: { eq: "soplica-wedel/plug-desktop.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
        plugMobile: file(relativePath: { eq: "soplica-wedel/plug-mobile.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
    }
`;

export default SoplicaWedel;
